import { Checkbox } from "antd";
import React, { useState } from "react";
import "./customCheckBox.scss";
import type {
  AbstractCheckboxProps,
  CheckboxChangeEvent,
} from "antd/lib/checkbox/Checkbox";

interface CheckboxProps extends AbstractCheckboxProps<any> {
  label?: any;
  checked: boolean;
  className?: string;
}

const CustomCheckBox = ({ label, className, ...props }: CheckboxProps) => {
  return (
    <Checkbox
      onChange={(e: CheckboxChangeEvent) => {
        props.onChange && props.onChange(e);
      }}
      {...props}
    >
      {label}
    </Checkbox>
  );
};

export default CustomCheckBox;
