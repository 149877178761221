import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/home/home";
import Layout from "./layouts/Layout";
import TrafficInsurance from "./page/products/trafficInsurance/trafficInsurance";
import { Contact } from "./page/contact/contact";
import Insurance from "./page/products/insurance/insurance";
import HealthInsurance from "./page/products/healthInsurance/healthInsurance";
import ScrollToTop from "./utils/scrolltoTop";
import HomeInsurance from "./page/products/homeInsurance/homeInsurance";
import TravelInsurance from "./page/products/travelInsurance/travelInsurance";
function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/urunlerimiz/trafik-sigortasi"
            element={<TrafficInsurance />}
          ></Route>
          <Route
            path="/urunlerimiz/tamamlayici-saglik-sigortasi"
            element={<HealthInsurance />}
          ></Route>
          <Route path="/urunlerimiz/kasko" element={<Insurance />}></Route>
          <Route
            path="/urunlerimiz/konut-sigortasi"
            element={<HomeInsurance />}
          ></Route>
          <Route
            path="/urunlerimiz/seyahat-sigortasi"
            element={<TravelInsurance />}
          ></Route>
          <Route path="/bilgi-merkezi/iletisim" element={<Contact />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default Router;
