import "./homeInsurance.scss";
import CustomInput from "../../../components/CustomInput/customInput";
import Button from "../../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import ProductCard from "../../../components/ProductsCard/ProductCard";
import CustomDatePicker from "../../../components/customDatePicker/customDatePicker";
import BreadCrumb from "../../../components/breadCrumbs/BreadCrumb";
import dayjs from "dayjs";
import { openNotificationWith } from "../../../utils/notification";
import { sendMail } from "../../../services/mail";
import { useRef, useState } from "react";
import CustomCheckBox from "../../../components/customCheckBox/customCheckBox";
import CustomModal from "../../../components/modal/modal";
import KvkkPage from "../../../components/kvkkPage/kvkkPage";
import ReCAPTCHA from "react-google-recaptcha";
import KonutBg from "../../../assets/images/konut.jpg";

const HomeInsurance = () => {
  const breadCrumb = ["Anasayfa", "Konut Sigortası"];
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [error, setError] = useState<string | null>(null);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  const formik = useFormik({
    initialValues: {
      toEmail: "damla.ulusoy@pointo.com.tr",
      subjectEnum: 4,
      nameSurname: "",
      tcNo: "",
      phoneNo: "",
      licencePlate: "",
      birthDate: "",
      email: "",
      occupation: "",
      documentNo: "",
      reCaptchaResponse: "",
    },
    validationSchema: Yup.object().shape({
      tcNo: Yup.string()
        .matches(/^[1-9]{1}\d{10}$/, "Geçerli bir TC kimlik numarası giriniz.")
        .required("TC kimlik numarası zorunludur."),
      phoneNo: Yup.string()
        .matches(/^\d{10}$/, "Telefon numaranizi basinda 0 olmadan giriniz")
        .required("Telefon numaranizi basinda 0 olmadan giriniz"),
      email: Yup.string()
        .email("Geçerli bir e-posta adresi giriniz")
        .required("E-posta adresi zorunludur"),
      nameSurname: Yup.string().required("Ad Soyad zorunludur."),
      birthDate: Yup.string().required("Doğum tarihi zorunludur."),
      reCaptchaResponse: Yup.string().required("Doğrulama yapnız."),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const response = await sendMail(values);
        if (response.status === 201) {
          openNotificationWith(
            "success",
            "Teklifiniz Alındı.",
            "",
            "notification-success"
          );
        }
      } catch (err) {
        openNotificationWith(
          "error",
          "Bir hata meydana geldi.",
          "",
          "notification-error"
        );
      } finally {
        setLoading(false);
        // setShow(false);
        formik.resetForm();
        setChecked(false);
      }
    },
  });

  const onCaptchaVerify = (token: string | null) => {
    if (token) {
      console.log("Captcha doğrulandı. Token:", token);
      formik.setFieldValue("reCaptchaResponse", token); // formik değerine token'i ekle
      setError(null); // reCAPTCHA doğrulandıysa hatayı temizle
    }
    if (checked) {
      // Checkbox işaretliyse, yani kullanıcı onayladıysa
      // ReCAPTCHA doğrulaması geçildiği zaman teklif formunu gönder
      formik.handleSubmit();
    } else {
      console.error("Captcha doğrulanamadı.");
      setError("reCAPTCHA doğrulanamadı"); // reCAPTCHA doğrulanmadıysa hata mesajı göster
    }
  };
  return (
    <div className="homeInsurance">
      <BreadCrumb data={breadCrumb} />
      <div className="container">
        <div className="homeInsurance_inner">
          <div className="homeInsurance_inner_information">
            <div className="homeInsurance_inner_information_bg">
              <img
                className="homeInsuranceImage"
                src={KonutBg}
                alt="homeInsuranceImage"
              />
            </div>
            <div className="homeInsurance_inner_information_text">
              <h1>Konut Sigortası Fiyatları İçin Hemen Teklif Alın</h1>
              <ul>
                <li>
                  Konut sigortası ile evinizi doğal afetler, hırsızlık ve
                  beklenmedik kazalara karşı koruma altına alın.
                </li>
                <li>
                  Evinizde huzurla oturun, biz her türlü riske karşı
                  yanınızdayız.
                </li>
              </ul>
            </div>
          </div>
          <div className="homeInsurance_inner_form">
            <p>Hızlı teklif almak ve Acentime’lı olmak için formu doldur;</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="homeInsurance_inner_form__content">
                <CustomInput
                  type="text"
                  className="customInput"
                  placeholder="T.C. Kimlik No"
                  name="tcNo"
                  onChange={formik.handleChange}
                  value={formik.values.tcNo}
                  onBlur={formik.handleBlur}
                  maxLength={11}
                  aria-invalid={
                    formik.errors?.tcNo && formik.touched.tcNo
                      ? "true"
                      : "false"
                  }
                  errorText={formik.errors?.tcNo}
                />
                <div className="homeInsurance_inner_form__content__body">
                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="Ad Soyad"
                    name="nameSurname"
                    value={formik.values.nameSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.nameSurname && formik.touched.nameSurname
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.nameSurname}
                  />
                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="Cep Telefonu"
                    name="phoneNo"
                    maxLength={10}
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.phoneNo && formik.touched.phoneNo
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.phoneNo}
                  />
                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="E-posta"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.email && formik.touched.email
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.email}
                  />
                  <CustomDatePicker
                    placeholder="Doğum Tarihi"
                    name="birthDate"
                    value={formik.values.birthDate}
                    onChange={(value, date) => {
                      if (typeof date !== "undefined" && date !== null) {
                        const selectedDate = dayjs(date);
                        if (selectedDate.isValid()) {
                          formik.setFieldValue(
                            "birthDate",
                            selectedDate.format("DD/MM/YYYY")
                          );
                        } else {
                          console.error("Geçersiz tarih:", date);
                        }
                      } else {
                        formik.setFieldValue("birthDate", ""); // Eğer date tanımlı değilse, formik değerini boş bırak
                      }
                    }}
                    errorId={formik.errors.birthDate}
                    errorInvalid={
                      formik.errors.birthDate && formik.touched.birthDate
                        ? "true"
                        : "false"
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="homeInsurance_inner_form__kvkk">
                <CustomCheckBox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className="customCheckBox"
                />
                <p>
                  Kişisel verilere yönelik
                  <a
                    onClick={handleModalToggle}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  >
                    Aydınlatma Metni
                  </a>
                  'ni okudum, aydınlatma yapıldığını kabul ediyorum.
                </p>
              </div>
              <div style={{ margin: "10px 0" }}>
                <ReCAPTCHA
                  sitekey="6LeYGOApAAAAANPoeT-G4W7k_NrDYqXtZfRlZihl"
                  ref={recaptchaRef}
                  onChange={onCaptchaVerify}
                />
              </div>
              <Button
                type="submit"
                text="Teklif Al"
                btnType="primary-btn"
                disabled={
                  !checked ||
                  Object.keys(formik.errors).length > 0 ||
                  !formik.dirty ||
                  !formik.isValid
                }
                style={{ width: "100%", height: "60px", marginTop: "10px" }}
                showLoading={loading}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="homeInsurance__productCard">
        <ProductCard />
      </div>
      <CustomModal show={showModal} setShow={setShowModal} centered>
        <div style={{ maxHeight: "500px", overflowY: "auto", padding: "1rem" }}>
          <KvkkPage onClose={handleModalToggle} />
        </div>
      </CustomModal>
    </div>
  );
};

export default HomeInsurance;
