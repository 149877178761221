import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import Navbar from "./navbar/navbar";
import Footer from "./footer/Footer";

const Layout = () => {
  interface MenuItem {
    title: string;
    link: string;
    subMenu?: MenuItem[];
  }
  const menuItems: MenuItem[] = [
    {
      title: "Ürünlerimiz",
      link: "",
      subMenu: [
        {
          title: "Trafik Sigortası",
          link: "/urunlerimiz/trafik-sigortasi",
        },
        {
          title: "Kasko",
          link: "/urunlerimiz/kasko",
        },
        {
          title: "Tamamlayıcı Sağlık Sigortası",
          link: "/urunlerimiz/tamamlayici-saglik-sigortasi",
        },
        {
          title: "Konut Sigortası",
          link: "/urunlerimiz/konut-sigortasi",
        },
        {
          title: "Seyahat Sigortası",
          link: "/urunlerimiz/seyahat-sigortasi",
        },
        {
          title: "Diğer Sigortalar",
          link: "/bilgi-merkezi/iletisim",
        },
      ],
    },
    // {
    //   title: "Kampanyalar",
    //   link: "/offer",
    // },
    // {
    //   title: "Poliçe İşlemleri",
    //   link: "/police-islemleri",
    //   subMenu: [
    //     {
    //       title: "Poliçe İptal Başvuru",
    //       link: "/police-islemleri/police-iptal-basvuru",
    //     },
    //     {
    //       title: "Poliçe İptal Takip",
    //       link: "/police-islemleri/police-islem-takip",
    //     },
    //   ],
    // },
    {
      title: "Bilgi Merkezi",
      link: "/bilgi-merkezi",
      subMenu: [
        {
          title: "İletişim",
          link: "/bilgi-merkezi/iletisim",
        },
      ],
    },
  ];
  return (
    <div className="layout">
      <div className="layout_body">
        <Navbar menuItems={menuItems} />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
