import { Modal, ModalProps } from "antd";
import React, { ReactNode } from "react";

type CustomModalProps = ModalProps & {
  show: boolean;
  setShow: (show: boolean) => void;
  children: ReactNode;
};

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  setShow,
  children,
  ...props
}) => {
  const handleCloseModal = () => setShow(false);

  if (!show) return null;

  return (
    <Modal
      open={show}
      onCancel={handleCloseModal}
      closable={false}
      styles={{
        footer: {
          display: "none",
        },
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
