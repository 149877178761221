import Icon from "@ant-design/icons";
import { notification } from "antd";
import { ReactComponent as ErrorIcon } from "../assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";

export const openNotificationWith = (
  type: string,
  title: string,
  description: string,
  classname: string
) => {
  notification.destroy();

  const showNotification = () => {
    if (type === "info") {
      notification[type]({
        message: title,
        description: description,
        className: classname,
        // @ts-ignore
        icon: <Icon component={InfoIcon} />,
      });
    } else if (type === "warning") {
      notification[type]({
        message: title,
        description: description,
        className: classname,
        // @ts-ignore
        icon: <Icon component={WarningIcon} />,
      });
    } else if (type === "error") {
      notification[type]({
        message: title,
        description: description,
        className: classname,
        // @ts-ignore
        icon: <Icon component={ErrorIcon} />,
      });
    } else if (type === "success") {
      notification[type]({
        message: title,
        description: description,
        className: classname,
        // @ts-ignore
        icon: <Icon component={SuccessIcon} />,
      });
    }
  };

  // Apply setTimeout conditionally based on the notification type
  if (type === "info" || type === "warning") {
    setTimeout(showNotification, 100);
  } else {
    showNotification();
  }
};
