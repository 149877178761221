import React from "react";
import ProductCard from "../../components/ProductsCard/ProductCard";
import carLogo from "../../assets/images/carFrame.png";

import carIcon from "../../assets/icons/carIcon.svg";
import homeIcon from "../../assets/icons/home.svg";
import carInsuranceIcon from "../../assets/icons/carInsurance.svg";
import phoneIcon from "../../assets/icons/protection.svg";
import familyIcon from "../../assets/icons/familyIcon.svg";
import otherInsuranceIcon from "../../assets/icons/otherInsurance.svg";
import planeIcon from "../../assets/icons/planeIcon.svg";
import acentimeLogo from "../../assets/icons/acentimeLogo.svg";
import "./home.scss";

const Home = () => {
  const cards = [
    {
      icon: carIcon,
      text: "TRAFİK SİGORTASI",
      link: "/urunlerimiz/trafik-sigortasi",
    },
    {
      icon: carInsuranceIcon,
      text: "KASKO SİGORTASI",
      link: "/urunlerimiz/kasko",
    },
    {
      icon: familyIcon,
      text: "TAMAMLAYICI SAĞLIK SİGORTASI",
      link: "/urunlerimiz/tamamlayici-saglik-sigortasi",
    },
    {
      icon: homeIcon,
      text: "KONUT SİGORTASI",
      link: "/urunlerimiz/konut-sigortasi",
    },
    {
      icon: planeIcon,
      text: "SEYAHAT SİGORTASI",
      link: "/urunlerimiz/seyahat-sigortasi",
    },

    {
      icon: otherInsuranceIcon,
      text: "DİĞER SİGORTA ÜRÜNLERİ",
      link: "/bilgi-merkezi/iletisim",
    },
  ];
  // const firstRow = cards.slice(0, 3);
  // const secondRow = cards.slice(3);
  return (
    <div className="home">
      <div className="home__inner">
        <div className="container">
          <div className="home__top">
            <div className="home__top__content">
              <div className="home__top__content__header">
                <span> Poliçen</span>
                <img src={acentimeLogo} alt="Acentime Logo" />
                <span style={{ paddingRight: "10px" }}>ile </span>
                <span> Anında Hazır</span>
              </div>

              <p>
                Sigorta şirketlerinden anında poliçenizi satın alın,
                görüntüleyin ve indirin!
              </p>
            </div>
            <div className="home__top__logo">
              <img src={carLogo} />
            </div>
          </div>
          <div className="home__bottom">
            <div className="home__bottom__row-top">
              {cards.map((card, index) => (
                <div className="home__bottom__card" key={index}>
                  <a href={card.link}>
                    <img src={card.icon} alt={`Icon ${index}`} />
                    <h3>{card.text}</h3>
                  </a>
                </div>
              ))}
            </div>
            {/* 
            <div className="home__bottom__row-bottom">
              {secondRow.map((card, index) => (
                <div className="home__bottom__card" key={index}>
                  <a href={card.link}>
                    <img src={card.icon} alt={`Icon ${index}`} />
                    <h3>{card.text}</h3>
                  </a>
                </div>
              ))}
            </div> */}
            {/* {cards.map((card, index) => (
              <div className="home__bottom__card" key={index}>
                <img src={card.icon} alt={`Icon ${index}`} />
                <h3>{card.text}</h3>
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <ProductCard />
    </div>
  );
};

export default Home;
