import axios, { AxiosRequestConfig } from "axios";
import LocalStorageService from "../services/LocalStorageService";
import { openNotificationWith } from "./notification";

const configs = {
  apiUrl: process.env.REACT_APP_BASE_API_URL,
};

const localStorageService = LocalStorageService.getService();

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorageService.getAccessToken();

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {};
    }
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response) {
      if (
        error.response.status === 401 &&
        originalRequest.url ===
          `${configs.apiUrl}/identity/login/refresh-token/`
      ) {
        // history.push("/login");
        openNotificationWith(
          "error",
          "Oturumunuzun Süresi Doldu",
          "Lütfen Tekrar Giriş Yapın",
          "notification-error"
        );
        // localStorageService.clearToken();
        window.location.href = "/login";
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorageService.getRefreshToken();

        return axios
          .post(`${configs.apiUrl}/identity/login/refresh-token?`, {
            refresh_token: refreshToken,
          })
          .then((res) => {
            if (res.status === 201) {
              localStorageService.setToken(res.data);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorageService.getAccessToken();
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            if (err) {
              localStorageService.clearToken();
              window.location.href = "/login";
            }
          });
      } else if (error.response.status === 400) {
        openNotificationWith(
          "error",
          "HATA",
          "Hatalı eksik parametreler bulunmaktadır.",
          "notification-error"
        );
      } else if (error.response.status === 403) {
        openNotificationWith(
          "error",
          "Yetkisiz işlem tespit edildi.",
          "Giriş sayfasına yönlendiriliyorsunuz.",
          "notification-error"
        );
        localStorageService.clearToken();
        window.location.href = "/login";
      } else if (error.response.status === 404) {
        openNotificationWith(
          "error",
          "Başarısız 404",
          "Lütfen Daha Sonra tekrar deneyin",
          "notification-error"
        );
      } else if (error.response.status === 500) {
        openNotificationWith(
          "error",
          "Sunucuya erişim sağlanamadı.",
          "Lütfen Daha Sonra tekrar deneyin",
          "notification-error"
        );
      } else if (error.response.status === 502) {
        openNotificationWith(
          "error",
          "Sunucuya erişim sağlanamadı.",
          "Lütfen Daha Sonra tekrar deneyin",
          "notification-error"
        );
        localStorageService.clearToken();
        window.location.href = "/login";
      }
    }

    /* switch (error.response.status) {
      case 400:
        notify(error);
        return Promise.reject(error);
      case 401:
        notify(error);
        localStorageService.clearToken();

        window.location.reload();

        post("api​token​refresh", {
          refresh_token: localStorageService.getRefreshToken(),
        }).then((res) => {
          if (res.status === 201) {
            localStorageService.setToken(res.data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorageService.getAccessToken();
            return axios(originalRequest);
          }
        });
        return Promise.reject(error);
      case 403:
        notify(error);
        return Promise.reject(error);
      case 404:
        // console.log(error);
        notify(error);
        return Promise.reject(error);
      case 408:
        notify(error);
        return Promise.reject(error);
      case 429:
        notify(error);
        return Promise.reject(error);
      case 500:
        notify(error);
        return Promise.reject(error);
      case 501:
        notify(error);
        return Promise.reject(error);
      case 502:
        notify(error);
        return Promise.reject(error);
      case 503:
        notify(error);
        return Promise.reject(error);
      case 504:
        notify(error);
        return Promise.reject(error);
      default:
        notify(error);
        return Promise.reject(error);
    } */

    return Promise.reject(error);
  }
);

const url = (endpoint: string) => {
  return `${configs.apiUrl}${endpoint}`;
};

export async function post(
  endpoint: string,
  data: object,
  headers?: AxiosRequestConfig
) {
  const reqUrl = url(endpoint);
  return axios
    .post(reqUrl, data, headers)
    .then((res) => res)
    .catch((error) => error.response);
}
export async function get(endpoint: string, headers?: object) {
  const reqUrl = url(endpoint);

  return axios
    .get(reqUrl, headers)
    .then((res) => res)
    .catch((error) => error.response);

  /* 
  return await axios
    .get(reqUrl)
    .then((res) => res)
    .catch((error) => error.response); */
}
export async function put(endpoint: string, data: object) {
  const reqUrl = url(endpoint);
  return await axios
    .put(reqUrl, data)
    .then((res) => res)
    .catch((error) => error.response);
}
export async function deletereq(endpoint: string, data: object) {
  const reqUrl = url(endpoint);
  return axios
    .delete(reqUrl, data)
    .then((res) => res)
    .catch((error) => error.response);
}
