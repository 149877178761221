import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { useEffect, useState } from "react";
import "./customDatePicker.scss";

interface CustomDatePickerProps {
  label?: string;
  placeholder: string;
  disabled?: boolean;
  errorText?: string;
  value?: string; // Değişiklik burada: string yerine dayjs.Dayjs | undefined
  onChange?: (value: string, date: dayjs.Dayjs | null) => void; // Değişiklik burada: string yerine dayjs.Dayjs | null
  defaultValue?: string;
  name?: string;
  format?: string;
  disabledDate?: (current: dayjs.Dayjs | undefined) => boolean;
  errorId?: string;
  errorInvalid?: "true" | "false" | undefined;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const CustomDatePicker = ({
  label,
  placeholder,
  disabled,
  value,
  errorText,
  onChange,
  defaultValue,
  name,
  format,
  disabledDate,
  errorId,
  errorInvalid,
  onBlur,
}: CustomDatePickerProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  useEffect(() => {
    if (value == "") setSelectedValue(undefined);
  }, [value]);

  return (
    <div className="custom-date-picker">
      {label && <label htmlFor="">{label}</label>}
      <DatePicker
        locale={locale}
        id={name}
        name={name}
        format={format ? format : "DD/MM/YYYY"}
        placeholder={placeholder}
        disabled={disabled}
        popupStyle={{ zIndex: "9999" }}
        value={selectedValue ? dayjs(selectedValue, "DD/MM/YYYY") : undefined}
        onChange={(date, dateString) => {
          let selectedDateString: string;
          if (Array.isArray(dateString)) {
            // Eğer dateString bir dizi ise, ilk elemanını al
            selectedDateString = dateString[0];
          } else {
            selectedDateString = dateString;
          }

          if (date) {
            const selectedDate = dayjs(date);
            if (selectedDate.isValid()) {
              onChange && onChange(selectedDateString, selectedDate);
              // Seçilen tarihi güncelle
              setSelectedValue(selectedDate.format("DD/MM/YYYY"));
            } else {
              console.error("Geçersiz tarih:", date);
            }
          } else {
            // Eğer date tanımlı değilse, onChange fonksiyonunu çağırmayalım
            console.error("Geçersiz tarih: null veya undefined");
          }
        }}
        placement="topLeft"
        aria-invalid={errorInvalid}
        aria-errormessage={errorId}
        onBlur={(e: any) => onBlur && onBlur(e)}
      />
      {errorInvalid == "true" && (
        <span className="errorTextCommon">{errorId}</span>
      )}
      {/* <div className="inputBoxErrorContent">{errorText}</div> */}
    </div>
  );
};

export default CustomDatePicker;
