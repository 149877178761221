import React from "react";
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import "./customTextArea.scss";

interface CustomTextAreaProps extends TextAreaProps {
  label?: string;
}

const CustomTextArea = ({ label, ...props }: CustomTextAreaProps) => {
  return (
    <div className="custom-text-area">
      <label htmlFor="">{label}</label>
      <TextArea {...props} />
    </div>
  );
};

export default CustomTextArea;
