import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import companyLogo from "../../assets/icons/Layer_1.svg";
import Button from "../../components/Button/Button";
import arrowIcon from "../../assets/icons/arrow.svg";
import phoneIcon from "../../assets/icons/phoneIcon.svg";
import burgerIcon from "../../assets/icons/hamburger.svg";
import CloseIcon from "../../assets/icons/cross-circle.svg";

interface MenuItem {
  title: string;
  link: string;
  subMenu?: MenuItem[];
}

interface NavbarProps {
  menuItems: MenuItem[];
}

const Navbar: React.FC<NavbarProps> = ({ menuItems }) => {
  const navigate = useNavigate();
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleContactRedirect = () => {
    navigate("/bilgi-merkezi/iletisim");
  };

  const handleSubMenuClick = (index: number) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(index);
    }
  };

  const handleMenuToggle = () => {
    if (!isMenuOpen) {
      // Eğer menü kapalıysa alt menüyü kapat
      setActiveSubMenu(null);
    }
    setIsMenuOpen(!isMenuOpen); // Menüyü aç/kapa
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const renderSubMenu = (subMenu: MenuItem[] | undefined, index: number) => {
    if (!subMenu) return null;

    //hamburger açiksa sub-menu / open classnamei kullanilmayacak.
    const ulClassName = isMenuOpen
      ? "sub-menu-ul"
      : activeSubMenu === index
      ? "sub-menu open"
      : "sub-menu";

    return (
      <ul className={ulClassName}>
        {subMenu.map((item, subIndex) => (
          <li key={subIndex}>
            <Link to={item.link} onClick={handleCloseMenu}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="home-navbar">
          <div className="home-navbar__logo">
            <Link to="/">
              <img src={companyLogo} alt="" />
            </Link>
          </div>
          <div className={`menu ${isMenuOpen ? "open" : ""}`}>
            <ul className="menu-ul">
              <div className="menu-header">
                <div className="close-menu" onClick={handleCloseMenu}>
                  <img src={CloseIcon} alt="Close Menu" />
                </div>
              </div>
              {menuItems.map((item, index) => (
                <li key={index} onClick={() => handleSubMenuClick(index)}>
                  <span>
                    {item.title}
                    {item.subMenu && (
                      <div
                        className={`menuArrowIcon ${
                          activeSubMenu === index ? "rotate" : ""
                        }`}
                      >
                        <img src={arrowIcon} alt={item.title} />
                      </div>
                    )}
                  </span>
                  {item.subMenu &&
                    activeSubMenu === index &&
                    renderSubMenu(item.subMenu, index)}
                </li>
              ))}
            </ul>
          </div>
          <div className="home-navbar__contact">
            <div className="home-navbar__contact__information">
              <p>Yardım Merkezi</p>
              <div className="home-navbar__contact__information__phone">
                <img src={phoneIcon} alt="" />
                <h2>0532 571 04 30</h2>
              </div>
            </div>
            <div className="home-navbar__contact_button">
              <Button
                text="Teklif Al"
                btnType="primary-btn"
                style={{ height: "50px", width: "170px" }}
                onClick={handleContactRedirect}
              />
            </div>
          </div>
          <div
            className={`burger-menu ${isMenuOpen ? "open" : ""}`}
            onClick={handleMenuToggle}
          >
            <img src={burgerIcon} alt="Burger Menu" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
