import React from "react";
import "./ProductCard.scss";
import collaborationIcon from "../../assets/icons/Collaboration.svg";
import tagIcon from "../../assets/icons/Tag.svg";
import securePaymentIcon from "../../assets/icons/SecurePayment.svg";

const ProductCard = () => {
  return (
    <div className="ProductsInfo">
      <div className="container">
        <h1>Neden Acentime?</h1>
        <div className="ProductsInfo__card">
          <div className="ProductsInfo__card__inner">
            <img src={securePaymentIcon} />
            <h2>Tam Uyum</h2>
            <p>
              Onlarca teklif arasından sizin için en uygun, en kapsamlı ve en
              ekonomik sigortayı buluyoruz. Şeffaf teminatlar ve net fiyatlarla
              kararınız hep güvende.
            </p>
          </div>
          <div className="ProductsInfo__card__inner">
            <img src={tagIcon} />
            <h2>En İyi Teklif</h2>
            <p>
              Önceliğimiz her zaman sizin bütçeniz. Çalıştığımız tüm
              şirketlerden sizin için "en iyi fiyat garantisi" sunuyoruz.
            </p>
          </div>
          <div className="ProductsInfo__card__inner">
            <img src={collaborationIcon} />
            <h2>7/24 Hizmet</h2>
            <p>
              Deneyimli sigorta danışmanlarımızla her an yanınızdayız. Hasar
              durumunda saniyeler içinde bize ulaşabilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
