import { Link } from "react-router-dom";
import NextIcon from "../../assets/icons/arrowRight.svg";
import "./breadCrumb.scss";

interface BreadCrumbProps {
  data: string[];
}

const BreadCrumb = ({ data }: BreadCrumbProps) => {
  return (
    <div className="breadcrumb-component">
      <div className="container">
        <div className="breadcrumb-component__inner">
          <ul className="breadcrumb-component--breadcrumb">
            {data.map((item, index) => (
              <li key={index}>
                {index !== data.length - 1 ? (
                  <Link to="/">
                    <span>{item}</span>
                  </Link>
                ) : (
                  <span>{item}</span>
                )}
                {index !== data.length - 1 && <img src={NextIcon} alt="" />}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
