import React from "react";
import "./Button.scss";
interface ButtonPropsTypes
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  width?: number;
  btnType?: string;
  icon?: string;
  showLoading?: boolean;
}

const Button = ({
  text,
  width,
  btnType,
  icon,
  showLoading,
  ...props
}: ButtonPropsTypes) => {
  return (
    <button
      className={btnType ? btnType : ""}
      style={{ width: width ? `${width}px` : "" }}
      {...props}
    >
      {icon && <img src={icon} alt="icon" />}
      {text}
      {showLoading && (
        <div className="loading">
          <div className="loading-spinner"></div>
        </div>
      )}
    </button>
  );
};

export default Button;
