import React from "react";
import Button from "../Button/Button";
import "./kvkkPage.scss";
interface KvkkPageProps {
  onClose: () => void; // Function to handle closing the modal
}

const KvkkPage: React.FC<KvkkPageProps> = ({ onClose }) => {
  return (
    <div className="kvkkContent">
      <h1>Kişisel Verilerin Korunması Kanunu (KVKK) Bilgilendirme Metni</h1>
      <p>
        6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”) USUL VE ESASLARI
        UYARINCA KİŞİSEL VERİLERİNİZİN KORUNMASI HAKKINDA SİGORTALI/SİGORTA
        ETTİREN AYDINLATMA METNİ 6698 sayılı Kişisel Verilerin Korunması Kanunu
        (“Kanun”) gereğince, kişinin özgeçmişi içerisinde yer alan adı/soyadı,
        adresi, doğum yeri ve tarihi, telefon numarası, elektronik posta adresi,
        öğrenim durumu, eğitim bilgileri, sağlık verileri, iş deneyimleri, din,
        ırk bilgileri, referansları ile ilgili kişiyi tanıtan her türlü bilgi ve
        belge, kişisel veri kapsamındadır. Yukarıda bahsi geçen kişisel
        verileriniz; “Veri Sorumlusu” olarak “Acentime sigorta aracılık
        hizmetleri limited şirketi”, “ACENTE ” tarafından aşağıda açıklanan
        kapsamda işlenebilecektir. Kişisel verilerin hangi amaçla işleneceği:
        Kişisel verileriniz; sigortacılık mevzuatı başta olmak üzere, kanunlar
        ve sair mevzuat kapsamında sigorta teklifleri ve bireysel emeklilik
        sözleşmeleri, poliçe ve sözleşme gereğinin ifa edilebilmesi, risk
        değerlendirmesi yapılabilmesi, sigorta satış ve pazarlama faaliyetleri,
        hasar ödeme ve asistans faaliyetleri başta olmak üzere sigorta
        şirketleri ve diğer iş ortaklarımız aracılığıyla sigorta sözleşmeleri
        kapsamında sizlere verilecek hizmetlerin yerine getirilmesi süreçlerinde
        sigorta primlerinin belirlenebilmesi, tahsilat yapılabilmesi,
        sigortacılık ve bireysel emeklilik ürün ve hizmetlerinin, avantaj veya
        kampanyaların sunulabilmesi, bilgilendirme, tanıtım, pazarlama ve satış
        da dahil olmak üzere her türlü iletişimin sağlanması ve bu amaçlarla
        ticari elektronik ileti gönderilebilmesi, mevzuatta belirtilen
        yükümlülüklerin ifa edilebilmesi, işlem yapanın/yaptıranın kimliğinin
        tespit edilebilmesi, elektronik ortamda veya basılı olarak kayıt ve
        belge düzenlenebilmesi, mevzuatta öngörülen evrak saklama, raporlama ve
        bilgilendirme yükümlülüklerinin yerine getirilebilmesi ve istatistiksel
        amaçlarla, Kişisel verileriniz, otomatik ya da otomatik olmayan
        yöntemlerle, “ ACENTE” ye ait internet sitesi, sosyal medya mecraları,
        mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik
        olarak toplanmaktadır. Eğitim, seminer, kurs, konferans vb. hizmetlerden
        yararlandığınız müddetçe, düzenlenen organizasyonlara katıldığınızda,
        web sitesini veya sosyal medya hesaplarını ziyaret ettiğinizde, sms
        gönderilerinde toplanabilecek, güncellenebilecek, Kişisel verileriniz,
        iş başvuru talebinizin ve ilgili pozisyona uygunluğunuzun
        değerlendirilmesi, ACENTE’nin insan kaynakları politikaları çerçevesinde
        işe alım süreçlerinin yürütülmesi, sonuçlandırılması, sonucun olumsuz
        olması durumlarında sonradan oluşacak benzer pozisyonlarda başvurunuzun
        değerlendirmeye alınması, bu kapsamda sizinle iletişime geçilmesi ve
        ACENTE’nin uyguladığı işe alım ilkelerinin geliştirilmesi ve
        iyileştirilmesi amaçları dâhilinde, işlenecektir. Hangi kişisel
        verilerinizi işliyoruz? Tarafımızca, bizimle paylaşmanız veya gerekli
        olması halinde işlemeye konu olabilecek kişisel verileriniz aşağıdaki
        gibidir: KİMLİK VERİSİ ADI, SOYADI, TCKN, DOĞUM TARİHİ, DOĞUM YERİ,
        MEDENİ DURUMU, CİNSİYETİ, MİLLİYETİ, İLETİŞİM VERİSİ TELEFONU,
        E-POSTASI, ADRESİ FİNANSAL VERİ BANKA HESAP BİLGİLERİ, KREDİ KARTI
        BİLGİSİ (CVV NUMARASI DAHİL), ÖZEL NİTELİKLİ KİŞİSEL VERİ NÜFUS VE
        EHLİYET SURETİ GİBİ BELGELERDE BULUNAN DİN İLE KAN GRUBU, SİGARA, ALKOL
        KULLANIMI, SAĞLIK GEÇMİŞİ, SAĞLIK BEYANI, KRONİK HASTALIĞI, HAMİLELİK
        DURUMU, DOĞUM RAPORU, EPİKRİZ RAPORU, DOKTOR MUAYENE FORMU, İLAÇ, REÇETE
        VE KUPÜRLERİ, İLERİ TANI YÖNTEMLERİNE AİT HER TÜRLÜ TETKİK, TESTLER VE
        SONUÇLARI, KİŞİNİN HASTALIĞI YA DA SİGORTA ŞİRKETİNİN ŞARTLARI KABUL
        ETTİĞİNE/ETMEDİĞİNE DAİR YAZDIĞI YAZI, ENGELLİ OLUP OLMADIĞI BİLGİSİ,
        DERNEK/SENDİKA/VAKIF ÜYELİKLERİ, SUNULAN İNDİRİM FIRSATLARINA GÖRE
        ÇEŞİTLİ VERİLER ÇALIŞMA VERİSİ ÇALIŞTIĞI ŞİRKET, DEPARTMANI, ÇALIŞMA
        ŞEKLİ, MESLEĞİ, İMZASI, MESLEK KARTI BİLGİLERİ GÖRSEL VE İŞİTSEL VERİ
        GERÇEK KİŞİYE AİT FOTOĞRAF, SES KAYITLARI, ARAÇ FOTOĞRAFI DİĞER ÇOCUK
        SAYISI, AİLE CÜZDANI, ARAÇ BİLGİLERİ (ARAÇ PLAKASI, MARKA, MODEL, MODEL
        YILI, MOTOR ŞASİ NUMARASI, RUHSAT TESCİL TARİHİ, RUHSAT ÖRNEĞİ,
        HASARSIZLIK BİLGİLERİ), POLİÇE BİLGİLERİ (MEVCUT POLİÇESİ OLUP OLMADIĞI,
        VARSA HANGİ ŞİRKETTEN OLDUĞU, POLİÇE NUMARASI, POLİÇE VADESİ), BOY,
        KİLO, PLANLADIĞI SEYAHAT GÜZERGAHI VE SÜRESİ, YABANCILARDA OTURMA İZNİNE
        AİT BİLGİLER, KONUT BİLGİLERİ, ASKERİ PERSONEL BİLGİLERİ, REFERANS
        BİLGİLERİ İMZA, YABANCI KİŞİNİN OTURMA İZNİ WEB SİTESİ KULLANIM VERİLERİ
        BAŞVURU FORMU DOLDURULMA TARİHİ, ALDIĞI VEYA ALAMADIĞI TEKLİFLER, ÜYE
        OLDUĞU TARİH, SİTEYE LOGİN OLMA SIKLIĞI/ZAMANLARI, SON LOGİN TARİHİ
        İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği:
        Toplanan kişisel verileriniz; yukarıda belirtilen sebepler, amaçlar ve
        Kanun’da yer alan düzenlemeler çerçevesinde; “ACENTE.” tarafından dâhili
        olarak kullanılacağı gibi Sigortacılık ve sair mevzuat çerçevesinde;
        Hazine ve Maliye Bakanlığı Sigortacılık Genel Müdürlüğü, Sigorta Bilgi
        ve Gözetim Merkezi başta olmak üzere zorunlu raporlamaların
        hazırlanabilmesi amacıyla çeşitli kurum ve kuruluşlara; reasürörlere,
        sigorta ve bireysel emeklilik şirketleri ve aracılarına, poliçe ve
        sözleşme taraflarına, doğrudan ve dolaylı yurtiçi ve yurtdışı topluluk
        şirketleri ve iştiraklerine, mevzuatta belirtilen amaçlara konu
        faaliyetlerini yürütmek üzere hizmet alınan, işbirliği yapılan kişi ve
        kuruluşlar ile destek hizmeti sağlayıcılarına mevzuatın izin verdiği
        ölçüde sigortacılık faaliyetinin yürütülebilmesi için hizmet alınan
        bilişim teknolojileri, aktüerya, ekspertiz şirketleri gibi özel
        kuruluşlara; sigortacılık hizmetlerimiz çerçevesinde fiyatlama, satış ve
        pazarlama işlemleri ile istatistiksel analizler yapmak üzere Sigorta
        Şirketlerine, Şubelerimize ve diğer iştiraklerimize, Sosyal Güvenlik
        Kurumu ve kanunen yetkili diğer kamu kurumları, faaliyetlerimizi
        yürütebilmek amacıyla işbirliği yaptığımız kurum ve kuruluşlarla,
        verilerin saklanması için hizmetlerinden faydalandığımız, fiziksel
        server ve/veya bulut hizmeti aldığımız yurt içi/yurt dışı kişi ve
        kurumlarla, ticari elektronik iletilerin gönderilmesi konusunda hizmet
        aldığımız yurt içi/yurt dışı firmalarla, anlaşmalı olduğumuz bankalarla,
        uluslararası sertifikasyon sahibi ödeme kuruluşlarıyla, yurtiçi/yurtdışı
        çeşitli reklam şirketleri, ajanslar ve anket şirketleriyle hastanelerle,
        eczanelerle ya da yurtiçi/yurtdışı diğer iş ortaklarımızla, kanunen
        yetkili kamu kurumları ve özel kişilere yurtiçi ve yurtdışında KVKK’nın
        8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve
        amaçları çerçevesinde, aktarılabilecektir. Kişisel Veri Toplamanın
        Yöntemi ve Hukuki Sebebi: Kişisel verileriniz, Kanunun 5. ve 6.
        maddelerinde belirtilen hukuki sebeplere dayanarak; “ACENTE.” Şubeleri,
        Çağrı Merkezi, İnternet Şubesi, dijital kanallar, poliçe ve sözleşme
        tarafları, aracılar ile bunların hizmet verdiği tüm kanallar, yurt
        içi/yurt dışı iştirakler, işbirliği yapılan kurum ve kuruluşlar, sigorta
        ve bireysel emeklilik şirketleri, resmi kurumlar, yurt içi/yurt dışı
        bankalar ve diğer üçüncü kişiler aracılığıyla; sözlü, yazılı veya
        elektronik ortamda; tamamen veya kısmen otomatik olan ya da herhangi bir
        veri kayıt sisteminin parçası olarak otomatik olmayan yollarla
        toplanabilecektir. Sigorta poliçeleri ve emeklilik sözleşmelerinin
        taraflarına ait kişisel/özel nitelikli kişisel veriler; merkez ve
        şubelerimiz, internet uygulamalarımız ve çağrı merkezimiz aracılığı ile
        doğrudan doğruya sizlerden ve sigorta sözleşmelerinden kaynaklanan
        yükümlülüklerin yerine getirilebilmesi için kamu kurumları tarafından
        tarafımıza erişim yetkisi verilen veri tabanlarından derlenmekte,
        Kişisel verileriniz, ACENTE ’nın insan kaynakları politikaları
        çerçevesinde işe alım süreçlerinin yürütülmesi, sonuçlandırılması ve bu
        kapsamda sizinle iletişime geçilmesi amaçlarıyla edinilmekte ve bu
        hukuki sebeple toplanan kişisel verileriniz otomatik ya da otomatik
        olmayan yöntemlerle, sözlü, yazılı ve elektronik ortam da dahil olmak
        üzere çeşitli toplama yöntemleri vasıtasıyla toplanmakta, Kişisel
        Verileriniz, “6698 sayılı Kişisel Verilerin Korunması Kanunu” ve sair
        mevzuat çerçevesinde sadece sigortacılık faaliyetlerinin yürütülmesi
        amacı ile ve bu amacın gerektirdiği yasal sürelerle sınırlı olarak
        işlenmektedir. Veri Sahibinin Kanun’un 11. maddesinde Sayılan Hakları:
        Kanun’un 11. maddesi uyarınca, kişisel veri sahibi olarak, yazılı veya
        Kişisel Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle ACENTE
        ’ye başvurarak, o Kişisel verilerinizin işlenip işlenmediğini öğrenme, o
        Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme, o Kişisel
        verileriniz işleniyorsa, o Kişisel verilerinizin işlenme amacını ve
        bunların amacına uygun kullanıp kullanılmadığını öğrenme, o Kişisel
        verileriniz yurt içinde veya yurt dışında üçüncü kişilere aktarılıyorsa,
        kişisel verilerinizin aktarıldığı üçüncü kişileri bilme, o Kişisel
        verilerinizin eksik veya yanlış işlenmiş olması halinde bunların
        düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme, o Kişisel
        verilerinizin Kanun ve ilgili diğer kanun hükümlerine uygun olarak
        işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
        kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini
        isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
        üçüncü kişilere bildirilmesini isteme, o İşlenen verilerin münhasıran
        otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
        sonucun ortaya çıkmasına itiraz etme, o Kişisel verilerinizin kanuna
        aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde, zararın
        giderilmesini talep etme haklarına sahipsiniz. [1] 10.03.2018 tarih ve
        30356 sayılı “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ”
        uyarınca; veri sahipleri, Kanun’un 11. maddesinde belirtilen hakları
        kapsamında taleplerini, yazılı olarak veya kayıtlı elektronik posta
        (KEP) adresi, güvenli elektronik imza, mobil imza ya da veri sahipleri
        tarafından ACENTE’ya daha önce bildirilen ve ACENTE’nın sisteminde
        kayıtlı bulunan elektronik posta adresini kullanmak suretiyle veya
        başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama
        vasıtasıyla ACENTE’ya iletir. Yukarıda belirtilen ( Kanun’un 11. Maddesi
        ) haklarınıza ilişkin kullanmak istediğiniz hakkınıza yönelik
        açıklamalarınızı içeren talebinizi internet sitemizde yer alan “ Başvuru
        Formu”nu tam ve eksiksiz bir şekilde doldurarak, kimlik fotokopiniz ve
        diğer iletişim bilgilerinizi de eklemek suretiyle ıslak imzalı olarak
        aşağıda ki adresine bizzat elden iletebilir, noter kanalıyla veya iadeli
        taahhütlü mektup yoluyla gönderebilirsiniz. Başvurunuzda yer alan
        talepleriniz, talebin niteliğine göre en kısa sürede ve en geç otuz gün
        içinde ACENTE tarafından ücretsiz olarak sonuçlandırılacaktır. Ancak,
        işlemin ACENTE için ayrıca bir maliyet gerektirmesi hâlinde, Kişisel
        Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret
        alınacaktır. 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun “Veri
        Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı 10. maddesi gereğince
        kişisel verilerimin kim tarafından, hangi amaçla işleneceği, işlenen
        kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, kişisel veri
        toplamanın yöntemi ve hukuki sebebi ve Kanun’un 11. maddesinde yer alan
        haklarım konusunda hazırlanan işbu Aydınlatma Metnini okudum, anladım ve
        veri sorumlusu sıfatına sahip, ACENTE tarafından bu konuda detaylı
        olarak bilgilendirildim.
      </p>
      <p> VERİ SORUMLUSU İLETİŞİM BİLGİLERİ</p>
      <p>ACENTİME SİGORTA ARACILIK HİZMETLERİ LİMİTED ŞİRKETLERİ.</p>
      <p> Mersis No: 0473054464600001 </p>
      <p> İletişim Linki: https://www.acentime.com</p>
      <p>
        Adres: Söğütözü Mahallesi, Söğütözü caddesi, Koç Kuleleri No: 2A/13
        Çankaya / Ankara
      </p>

      <Button
        text="Kapat"
        btnType="primary-btn"
        style={{ height: "50px", marginTop: "20px", width: "100%" }}
        onClick={onClose}
      />
    </div>
  );
};

export default KvkkPage;
