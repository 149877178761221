import React from "react";
import { Link } from "react-router-dom"; // Link component
import acıbademLogo from "../../assets/images/acıbademLogo.png";
import akSigortaLogo from "../../assets/images/akSigortaLogo.png";
import allianzLogo from "../../assets/images/allianz.png";
import anadoluSigorta from "../../assets/images/anadoluSigortaLogo.png";
import anadoluHayat from "../../assets/images/anadoluHayat.png";
import axaSigorta from "../../assets/images/axxa.png";
import dogaSigorta from "../../assets/images/dogasigorta.png";
import sompoSigorta from "../../assets/images/somposigorta.png";
import neovaSigorta from "../../assets/images/neova.png";
import quickSigorta from "../../assets/images/quick.png";
import hdiSigorta from "../../assets/images/hdi.png";
import raySigorta from "../../assets/images/ray.png";
import phoneIcon from "../../assets/icons/phoneIcon.svg";
import companyLogo from "../../assets/icons/Layer_1.svg";
import "./Footer.scss";

const Footer = () => {
  type ProductItem = {
    menuName: string;
    href: string;
  };

  const insuranceItems = [
    // { menuName: "Kasko Sigortası", href: "/bilgi-merkezi/iletisim" },
    // { menuName: "Trafik Sigortası" },
    // { menuName: "Tamamlayıcı Sağlık Sigortası" },
    // { menuName: "Konut Sigortası" },
    // { menuName: "Seyahat Sigortası" },
    { menuName: "Allianz Sigorta" },
    { menuName: "Anadolu Sigorta" },
    { menuName: "Axa Sigorta" },
    { menuName: "Doğa Sigorta" },
    { menuName: "Hepiyi Sigorta" },
    { menuName: "Tüm Anlaşmalı Şirketler" },
  ];

  const productsMenuItems: ProductItem[] = [
    { menuName: "Kasko", href: "/urunlerimiz/kasko" },
    { menuName: "Trafik Sigortası", href: "/urunlerimiz/trafik-sigortasi" },
    {
      menuName: "Tamamlayıcı Sağlık Sigortası",
      href: "/urunlerimiz/tamamlayici-saglik-sigortasi",
    },
    { menuName: "Konut Sigortası", href: "/urunlerimiz/konut-sigortasi" },
    { menuName: "Seyahat Sigortası", href: "/urunlerimiz/seyahat-sigortasi" },
    { menuName: "Diğer Sigortalar", href: "/bilgi-merkezi/iletisim" },
  ];

  const additionalItems = [
    // { href: "/biz-kimiz", menuName: "Biz Kimiz" },
    { href: "/bilgi-merkezi/iletisim", menuName: "İletişim" },
  ];

  const productsLogo = [
    { logo: acıbademLogo },
    { logo: akSigortaLogo },
    { logo: allianzLogo },
    { logo: anadoluSigorta },
    { logo: anadoluHayat },
    { logo: axaSigorta },
    { logo: dogaSigorta },
    { logo: sompoSigorta },
    { logo: neovaSigorta },
    { logo: quickSigorta },
    { logo: hdiSigorta },
    { logo: raySigorta },
  ];

  return (
    <div className="footer">
      <div className="footer-border">
        <div className="container">
          <div className="footer__acentimeLogo">
            {productsLogo.map((item, index) => (
              <img key={index} src={item.logo} alt={`logo_${index}`} />
            ))}
          </div>
        </div>
      </div>
      <div className="footer-border">
        <div className="container">
          <div className="footer__acentimeContact">
            <img src={companyLogo} alt="companyLogo" />
            <div className="footer__acentimeContact__information">
              <p>Yardım Merkezi</p>
              <div className="footer__acentimeContact__information__phone">
                <img src={phoneIcon} alt="phoneIcon" />
                <h2>0532 571 04 30</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-border">
        <div className="container">
          <div className="footer__products">
            <div className="footer__products__inner">
              <h1>Ürünlerimiz</h1>

              <div className="footer__products__inner--menu">
                {productsMenuItems.map((item, index) => (
                  <Link key={index} to={item.href}>
                    {item.menuName}
                  </Link>
                ))}
              </div>
            </div>
            <div className="footer__products__inner">
              <h1>Sigorta Şirketleri</h1>
              <div className="footer__products__inner--menu">
                {insuranceItems.map((item, index) => (
                  <div key={index + 7}>{item.menuName}</div>
                ))}
              </div>
            </div>

            <div className="footer__products__inner">
              <h1>Hakkımızda</h1>
              <div className="footer__products__inner--menu">
                {additionalItems.map((item, index) => (
                  <Link key={index} to={item.href}>
                    {item.menuName}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-border">
        <div className="container">
          <div className="footer__info">
            <p>Copyright ® 2024 · Designed & Developed by Pointo</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
