import CustomInput from "../../../components/CustomInput/customInput";
import Button from "../../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./trafficInsurance.scss";
import ProductCard from "../../../components/ProductsCard/ProductCard";
import CustomDatePicker from "../../../components/customDatePicker/customDatePicker";
import BreadCrumb from "../../../components/breadCrumbs/BreadCrumb";
import dayjs from "dayjs";
import { openNotificationWith } from "../../../utils/notification";
import { sendMail } from "../../../services/mail";
import CustomCheckBox from "../../../components/customCheckBox/customCheckBox";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../components/modal/modal";
import KvkkPage from "../../../components/kvkkPage/kvkkPage";
import ReCAPTCHA from "react-google-recaptcha";
import TrafficInsuranceBg from "../../../assets/images/automobile-accident-street 1.png";

const TrafficInsurance = () => {
  const breadCrumb = ["Anasayfa", "Trafik Sigortası"];
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [error, setError] = useState<string | null>(null);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  const formik = useFormik({
    initialValues: {
      toEmail: "damla.ulusoy@pointo.com.tr",
      subjectEnum: 1,
      nameSurname: "",
      tcNo: "",
      phoneNo: "",
      licencePlate: "",
      birthDate: "",
      email: "",
      occupation: "",
      documentNo: "",
      reCaptchaResponse: "",
    },
    validationSchema: Yup.object().shape({
      tcNo: Yup.string()
        .matches(/^[1-9]{1}\d{10}$/, "Geçerli bir TC kimlik numarası giriniz.")
        .required("TC kimlik numarası zorunludur."),
      nameSurname: Yup.string().required("Ad Soyad zorunludur."),
      phoneNo: Yup.string()
        .matches(/^\d{10}$/, "Telefon numaranizi basinda 0 olmadan giriniz")
        .required("Telefon numaranizi basinda 0 olmadan giriniz"),
      email: Yup.string()
        .email("Geçerli bir e-posta adresi giriniz")
        .required("E-posta adresi zorunludur"),
      birthDate: Yup.string().required("Doğum tarihi zorunludur."),
      documentNo: Yup.string().required("Belge numarası zorunludur."),
      licencePlate: Yup.string().required("Plaka zorunludur."),
      reCaptchaResponse: Yup.string().required("Doğrulama yapnız."),

      // tcNo: Yup.string().matches(
      //   /^\d{10}$|^[1-9]{1}[0-9]{9}[02468]{1}$/,
      //   "Geçerli bir TC kimlik numarası veya vergi numarası giriniz."
      // ),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const response = await sendMail(values);
        if (!checked) {
          openNotificationWith(
            "error",
            "KVKK Onayınızı Vermelisiniz.",
            "",
            "notification-error"
          );
          return;
        }
        if (response.status === 201) {
          openNotificationWith(
            "success",
            "Teklifiniz Alındı.",
            "",
            "notification-success"
          );
        }
      } catch (err) {
        openNotificationWith(
          "error",
          "Bir hata meydana geldi.",
          "",
          "notification-error"
        );
      } finally {
        setLoading(false);
        recaptchaRef.current?.reset();
        formik.resetForm();
        setChecked(false);
      }
    },
  });

  // useEffect(() => {
  //   if (formik.values.reCaptchaResponse) {
  //     console.log(
  //       "Captcha doğrulandı. Token:",
  //       formik.values.reCaptchaResponse
  //     );
  //     formik.setFieldValue(
  //       "reCaptchaResponse",
  //       formik.values.reCaptchaResponse
  //     ); // formik değerine token'i ekle
  //     setError(null); // reCAPTCHA doğrulandıysa hatayı temizle
  //   } else {
  //     console.error("Captcha doğrulanamadı.");
  //     setError("reCAPTCHA doğrulanamadı"); // reCAPTCHA doğrulanmadıysa hata mesajı göster
  //   }
  // }, [formik.values]);

  const onCaptchaVerify = (token: string | null) => {
    if (token) {
      console.log("Captcha doğrulandı. Token:", token);
      formik.setFieldValue("reCaptchaResponse", token); // formik değerine token'i ekle
      setError(null); // reCAPTCHA doğrulandıysa hatayı temizle
    } else {
      console.error("Captcha doğrulanamadı.");
      setError("reCAPTCHA doğrulanamadı"); // reCAPTCHA doğrulanmadıysa hata mesajı göster
    }
  };
  return (
    <div className="trafficInsurance">
      <BreadCrumb data={breadCrumb} />
      <div className="container">
        <div className="traffic-insurance">
          <div className="traffic-insurance_information">
            <div className="traffic-insurance_information_bg">
              <img src={TrafficInsuranceBg} alt="TrafficInsuranceImage" />
            </div>
            <div className="traffic-insurance_information_text">
              <h1>Trafik Sigortası Fiyatları için Teklif Alın</h1>
              <ul>
                <li>
                  Acentime'nin trafik sigortası ile aracınızı güvence altına
                  alın.
                </li>
                <li>
                  Trafikteki riskleri en aza indirip huzurlu bir sürüş deneyimi
                  yaşayın.
                </li>
                <li>Siz güveninizi korurken biz yanınızdayız.</li>
              </ul>
            </div>
          </div>
          <div className="traffic-insurance_form">
            <p>Hızlı teklif almak ve Acentime’lı olmak için formu doldur;</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="traffic-insurance_form__content">
                <CustomInput
                  type="text"
                  className="customInput"
                  placeholder="T.C. Kimlik No"
                  name="tcNo"
                  onChange={formik.handleChange}
                  value={formik.values.tcNo}
                  onBlur={formik.handleBlur}
                  maxLength={11}
                  aria-invalid={
                    formik.errors?.tcNo && formik.touched.tcNo
                      ? "true"
                      : "false"
                  }
                  errorText={formik.errors?.tcNo}
                />
                <div className="traffic-insurance_form__content__body">
                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="Ad Soyad"
                    name="nameSurname"
                    value={formik.values.nameSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.nameSurname && formik.touched.nameSurname
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.nameSurname}
                  />

                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="Cep Telefonu"
                    name="phoneNo"
                    maxLength={10}
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.phoneNo && formik.touched.phoneNo
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.phoneNo}
                  />
                  <CustomInput
                    type="text"
                    new-customer-modal__title
                    className="customInput"
                    placeholder="E-posta"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.email && formik.touched.email
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.email}
                  />
                  <CustomInput
                    type="text"
                    className="customInput"
                    placeholder="Plaka"
                    name="licencePlate"
                    value={formik.values.licencePlate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    aria-invalid={
                      formik.errors.licencePlate && formik.touched.licencePlate
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors.licencePlate}
                  />
                  <CustomDatePicker
                    placeholder="Doğum Tarihi"
                    name="birthDate"
                    value={formik.values.birthDate}
                    onChange={(value, date) => {
                      if (typeof date !== "undefined" && date !== null) {
                        const selectedDate = dayjs(date);
                        if (selectedDate.isValid()) {
                          formik.setFieldValue(
                            "birthDate",
                            selectedDate.format("DD/MM/YYYY")
                          );
                        } else {
                          console.error("Geçersiz tarih:", date);
                        }
                      } else {
                        formik.setFieldValue("birthDate", ""); // Eğer date tanımlı değilse, formik değerini boş bırak
                      }
                    }}
                    errorId={formik.errors.birthDate}
                    errorInvalid={
                      formik.errors.birthDate && formik.touched.birthDate
                        ? "true"
                        : "false"
                    }
                    onBlur={formik.handleBlur}
                  />
                  <CustomInput
                    showTooltip
                    type="text"
                    className="customInput"
                    placeholder="Belge No"
                    name="documentNo"
                    onChange={formik.handleChange}
                    value={formik.values.documentNo}
                    onBlur={formik.handleBlur}
                    maxLength={11}
                    aria-invalid={
                      formik.errors?.documentNo && formik.touched.documentNo
                        ? "true"
                        : "false"
                    }
                    errorText={formik.errors?.documentNo}
                  />
                </div>
              </div>
              <div className="traffic-insurance_form__kvkk">
                <CustomCheckBox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className="customCheckBox"
                />
                <p>
                  Kişisel verilere yönelik
                  <a
                    onClick={handleModalToggle}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  >
                    Aydınlatma Metni
                  </a>
                  'ni okudum, aydınlatma yapıldığını kabul ediyorum.
                </p>
              </div>
              {error && <div style={{ color: "red" }}>{error}</div>}{" "}
              <div style={{ margin: "10px 0" }}>
                <ReCAPTCHA
                  className="g-recaptcha"
                  sitekey="6LeYGOApAAAAANPoeT-G4W7k_NrDYqXtZfRlZihl"
                  ref={recaptchaRef}
                  onChange={onCaptchaVerify}
                />
              </div>
              <Button
                type="submit"
                text="Teklif Al"
                btnType="primary-btn"
                disabled={
                  !checked ||
                  Object.keys(formik.errors).length > 0 ||
                  !formik.dirty ||
                  !formik.isValid
                }
                style={{ width: "100%", height: "60px", marginTop: "10px" }}
                showLoading={loading}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="trafficInsurance__productCard">
        <ProductCard />
      </div>
      <CustomModal show={showModal} setShow={setShowModal} centered>
        <div style={{ maxHeight: "500px", overflowY: "auto", padding: "1rem" }}>
          <KvkkPage onClose={handleModalToggle} />
        </div>
      </CustomModal>
    </div>
  );
};

export default TrafficInsurance;
