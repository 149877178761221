import { Select } from "antd";
import type { SelectProps } from "antd/lib/select";
import "./selectComponent.scss";

interface SelectComponentProps extends SelectProps {
  label?: string;
  errorText?: string;
  searchProductsMultiple?: boolean;
  customOptions?: [];
}

const SelectComponent = ({
  label,
  errorText,
  searchProductsMultiple = false,
  customOptions = [],
  ...props
}: SelectComponentProps) => {
  return (
    <div className={`custom-select`}>
      {label && (
        <label className={`${props.disabled && "disabled"}`} htmlFor="">
          {label}
        </label>
      )}
      <Select
        {...props}
        value={props.value ? props.value : undefined}
        aria-errormessage="select-error"
      />
      <span id="select-error" className="custom-select__error">
        {errorText}
      </span>
    </div>
  );
};

export default SelectComponent;
