import { Tooltip } from "antd";
import InfoIcon from "../../assets/icons/InfoIcon.svg";
import PassportIcon from "../../assets/icons/Passport.svg";
import React, { useState } from "react";
import "./style.scss";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
  error?: boolean;
  errorText?: string;
  className?: string;
  label?: string;
  showCountdown?: boolean;
  countdown?: number;
  onPressEnter?: () => void;
  isInfoInput?: boolean;
  editMode?: boolean;
  editModeFn?: () => void;
  editModeText?: string;
  showTooltip?: boolean;
  tooltipText?: string;
}

const CustomInput = ({
  type,
  error,
  errorText,
  className,
  label,
  showCountdown,
  countdown,
  onPressEnter,
  isInfoInput,
  editMode,
  editModeFn,
  editModeText,
  showTooltip = false,
  tooltipText,
  ...props
}: CustomInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false); // State for popup
  // const togglePopup = () => {
  //   setShowPopup(!showPopup);
  // };

  return (
    <div className="inputBoxWrapper">
      {label && (
        <label htmlFor="">
          {label}{" "}
          {editMode && (
            <button onClick={editModeFn} className="editMode primary-btn">
              {editModeText}
            </button>
          )}
        </label>
      )}
      {showTooltip && (
        <Tooltip className="inputBoxWrapper__tooltip" title={tooltipText}>
          <img
            src={InfoIcon}
            alt=""
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
          />
        </Tooltip>
      )}

      <input
        className={`inputbox ${isFocus ? "input-focus" : ""} ${
          className ? className : ""
        }`}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onPressEnter && onPressEnter();
          }
        }}
        style={{
          paddingLeft: isInfoInput ? "20px" : "",
          paddingRight: editMode ? "70px" : "",
          outline: error ? "1px solid red" : "",
        }}
        aria-errormessage="inputBoxErrorContent"
        {...props}
      />
      {editMode === true && props.maxLength && (
        <div className="inputBoxWrapper__count">
          {/* @ts-ignore */}
          {props.value!.length}/{props.maxLength}
        </div>
      )}
      {/* Popup content */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <img className="passportIcon" src={PassportIcon} alt="" />
            <p>
              Belge numarasından kasıt ne ise onun ile ilgili açıklama bu alana
              gelecek
            </p>
          </div>
        </div>
      )}

      {/* {type === "password" && (
        <img
          onClick={() => setShowPassword(!showPassword)}
          src={showPassword ? hidePasswordIcon : showPasswordIcon}
          alt=""
        />
      )} */}

      <div className="inputBoxErrorContent">{errorText}</div>
    </div>
  );
};

export default CustomInput;
