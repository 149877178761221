import Icon from "../../assets/images/location-pin.png";
import "./contact.scss";
import { motion } from "framer-motion";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Button from "../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/CustomInput/customInput";
import SelectComponent from "../../components/selectComponent/selectComponent";
import ProductCard from "../../components/ProductsCard/ProductCard";
import BreadCrumb from "../../components/breadCrumbs/BreadCrumb";
import { sendMail } from "../../services/mail";
import { openNotificationWith } from "../../utils/notification";
import CustomTextArea from "../../components/customTextArea/customTextArea";
import CustomCheckBox from "../../components/customCheckBox/customCheckBox";
import { useRef, useState } from "react";
import CustomModal from "../../components/modal/modal";
import KvkkPage from "../../components/kvkkPage/kvkkPage";
import ReCAPTCHA from "react-google-recaptcha";

// import "./contact.scss";

let DefaultIcon = L.icon({
  iconUrl: Icon,
  iconSize: [30, 30],
});

L.Marker.prototype.options.icon = DefaultIcon;
export const Contact = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const formik = useFormik({
    initialValues: {
      toEmail: "damla.ulusoy@pointo.com.tr",
      subjectEnum: 6,
      nameSurname: "",
      tcNo: "",
      phoneNo: "",
      licencePlate: "",
      birthDate: "",
      email: "",
      occupation: "",
      documentNo: "",
      contactTopicEnum: 0,
      reCaptchaResponse: "",
    },
    validationSchema: Yup.object().shape({
      phoneNo: Yup.string()
        .matches(
          /^\d{10}$/, // Telefon numarası için 10 basamaklı bir sayıya eşleşmeli
          "Telefon numaranizi basinda 0 olmadan giriniz"
        )
        .required("Telefon numaranizi basinda 0 olmadan giriniz"),
      email: Yup.string()
        .email("Geçerli bir e-posta adresi giriniz")
        .required("E-posta adresi zorunludur"),

      nameSurname: Yup.string().required("Ad Soyad zorunludur."),
      contactTopicEnum: Yup.number().min(1, "Lütfen seçim yapınız."),
      reCaptchaResponse: Yup.string().required("Plaka zorunludur."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await sendMail(values);
        if (response.status === 201) {
          openNotificationWith(
            "success",
            "Teklifiniz Alındı.",
            "",
            "notification-success"
          );
        }
      } catch (err) {
        openNotificationWith(
          "error",
          "Bir hata meydana geldi.",
          "",
          "notification-error"
        );
      } finally {
        setLoading(false);
        // setShow(false);
        recaptchaRef.current?.reset();
        formik.resetForm();
        setChecked(false);
      }
    },
  });
  console.log("formik", formik.values);
  const defaultProps = {
    center: {
      lat: 39.91464583373624,
      lng: 32.80389550211,
    },
    zoom: 17,
    marker1: {
      lat: 39.91464583373624,
      lng: 32.80389550211,
    },
  };

  const breadCrumb = ["Anasayfa", "İletişim"];

  const onCaptchaVerify = (token: string | null) => {
    if (token) {
      console.log("Captcha doğrulandı. Token:", token);
      formik.setFieldValue("reCaptchaResponse", token); // formik değerine token'i ekle
      setError(null); // reCAPTCHA doğrulandıysa hatayı temizle
    } else {
      console.error("Captcha doğrulanamadı.");
      setError("reCAPTCHA doğrulanamadı"); // reCAPTCHA doğrulanmadıysa hata mesajı göster
    }
  };

  return (
    <div>
      <BreadCrumb data={breadCrumb} />
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <div className="container">
          <div className="contact">
            <div className="contact__map">
              <div
                style={{
                  width: "100%",
                  height: "233px",
                  marginTop: "30px",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <MapContainer
                  center={defaultProps.center}
                  zoom={defaultProps.zoom}
                  style={{
                    height: "100%",
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={defaultProps.center}>
                    <Popup>
                      Söğütözü Mahallesi, Söğütözü caddesi, Koç Kuleleri No:
                      2A/13 Çankaya / Ankara
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
            <div
              className="g-recaptcha"
              data-sitekey="6LeYGOApAAAAANPoeT-G4W7k_NrDYqXtZfRlZihl"
            ></div>
            <div className="contact__information">
              <div className="contact__information__card">
                <h2>İletişim Bilgilerimiz</h2>
                <div className="contact__information__card__contact">
                  <div className="contact__information__card__contact__adress">
                    <h3>Adres</h3>
                    <p>
                      Söğütözü Mahallesi, Söğütözü caddesi, Koç Kuleleri No:
                      2A/13 Çankaya / Ankara
                    </p>
                  </div>
                  <div className="contact__information__card__contact__phone">
                    <h3>Telefon</h3>{" "}
                    <div className="contact__information__card__number">
                      <div className="contact__information__card__number__inner">
                        {/* <h4>Mobil</h4> */}
                        <p>0532 571 04 30</p>
                      </div>
                      {/* <div className="contact__information__card__number__inner">
                        <h4 className="min-width-45">Faks</h4>
                        <p>0312 909 32 46</p>
                      </div> */}
                      {/* <div className="contact__information__card__number__inner">
                        <h4>Mobil</h4>
                        <p>0555 555 55 55</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="contact__information__card__contact__email">
                    {" "}
                    <h3>E Posta Adresleri</h3>
                    <div className="contact__information__card__number">
                      <div className="contact__information__card__number__inner ">
                        <h4>Genel</h4>
                        <p>info@acentime.com.tr</p>
                      </div>
                      <div className="contact__information__card__number__inner">
                        <h4 className="min-width-50">Teklif</h4>
                        <p>teklif@acentime.com.tr</p>
                      </div>
                      <div className="contact__information__card__number__inner">
                        <h4>Muhasebe</h4>
                        <p>finans@acentime.com.tr</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact__information__card">
                <div className="contact__form">
                  <p>
                    İletişim Formunu Kullanarak Bize Hızlıca Ulaşabilirsiniz
                  </p>

                  <form>
                    <div className="contact__form__content">
                      <div className="contact__form__content__body">
                        <CustomInput
                          type="text"
                          new-customer-modal__title
                          className="customInput"
                          placeholder="Ad Soyad"
                          name="nameSurname"
                          value={formik.values.nameSurname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-invalid={
                            formik.errors.nameSurname &&
                            formik.touched.nameSurname
                              ? "true"
                              : "false"
                          }
                          errorText={formik.errors.nameSurname}
                        />
                        <CustomInput
                          type="text"
                          new-customer-modal__title
                          className="customInput"
                          placeholder="Cep Telefonu"
                          name="phoneNo"
                          maxLength={10}
                          value={formik.values.phoneNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-invalid={
                            formik.errors.phoneNo && formik.touched.phoneNo
                              ? "true"
                              : "false"
                          }
                          errorText={formik.errors.phoneNo}
                        />
                        <CustomInput
                          type="text"
                          new-customer-modal__title
                          className="customInput"
                          placeholder="E-posta"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-invalid={
                            formik.errors.email && formik.touched.email
                              ? "true"
                              : "false"
                          }
                          errorText={formik.errors.email}
                        />

                        <SelectComponent
                          placeholder="Konu Seçiniz"
                          options={[
                            { value: 0, label: "Lütfen Seçim Yapınız" },
                            { value: 1, label: "Trafik Sigortası" },
                            { value: 2, label: "Kasko" },
                            { value: 3, label: "Tamamlayıcı Sağlık Sigortası" },
                            { value: 4, label: "Konut Sigortası" },
                            { value: 5, label: "Seyahat Sigortası" },
                            { value: 6, label: "Diğer Konular" },
                          ]}
                          value={formik.values.contactTopicEnum}
                          onChange={(e) => {
                            formik.setFieldValue("contactTopicEnum", e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <CustomTextArea
                        showCount
                        maxLength={150}
                        rows={2}
                        placeholder="Mesajınızı bu alana yazabilirsiniz"
                        value={formik.values.occupation}
                        onChange={(e) => {
                          formik.setFieldValue("occupation", e.target.value);
                        }}
                        style={{ resize: "none" }}
                      />
                      {/* <CustomInput
                        type="text"
                        className="customInput"
                        placeholder="Mesajınızı bu alana yazabilirsiniz"
                        name="nameSurname"
                        onChange={formik.handleChange}
                        value={formik.values.nameSurname}
                        onBlur={formik.handleBlur}
                        maxLength={11}
                        aria-invalid={
                          formik.errors?.nameSurname &&
                          formik.touched.nameSurname
                            ? "true"
                            : "false"
                        }
                        errorText={formik.errors?.nameSurname}
                      /> */}
                    </div>
                    <div className="contact__form__content__kvkk">
                      <CustomCheckBox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        className="customCheckBox"
                      />
                      <p>
                        Kişisel verilere yönelik
                        <a
                          onClick={handleModalToggle}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        >
                          Aydınlatma Metni
                        </a>
                        'ni okudum, aydınlatma yapıldığını kabul ediyorum.
                      </p>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <ReCAPTCHA
                        className="g-recaptcha"
                        sitekey="6LeYGOApAAAAANPoeT-G4W7k_NrDYqXtZfRlZihl"
                        ref={recaptchaRef}
                        onChange={onCaptchaVerify}
                      />
                    </div>
                    <Button
                      type="submit"
                      text="Teklif Al"
                      btnType="primary-btn"
                      disabled={
                        !checked ||
                        Object.keys(formik.errors).length > 0 ||
                        !formik.dirty ||
                        !formik.isValid
                      }
                      style={{
                        width: "100%",
                        height: "60px",
                        marginTop: "20px",
                      }}
                      showLoading={loading}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductCard />
        <CustomModal show={showModal} setShow={setShowModal} centered>
          <div
            style={{ maxHeight: "500px", overflowY: "auto", padding: "1rem" }}
          >
            <KvkkPage onClose={handleModalToggle} />
          </div>
        </CustomModal>
      </motion.div>
    </div>
  );
};
